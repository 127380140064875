<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Galerie page d\'acceuil' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header py-0">
            <vb-headers-card-header-4 :data="headerData" />
          </div>
          <div class="card-body">
            <div class="d-flex flex-wrap mb-4">
              <vb-controls-button-1
                :data="{ title: 'Ajouter Galerie', icon: 'fe fe-plus-circle', type: 'primary' }"
                @click="addSlider"
              />
            </div>
            <div class="row">
              <a-card v-for="(item, index) in dataSource" :key="index" class="col-md-3 m-4  ">
                <template #cover>
                  <carousel :items-to-show="1">
                    <slide v-for="slide in item.img" :key="slide.order">
                      <div :class="$style.itemContent">
                        <div :class="$style.itemControl">
                          <div :class="$style.itemControlContainer">
                            <a-button-group>
                              <a-button @click="handlePreview(slide.url)"
                                ><SearchOutlined
                              /></a-button>
                              <a-tooltip>
                                <template #title>Modifier l'ordre de l'image</template>
                                <a-dropdown>
                                  <template #overlay>
                                    <a-menu
                                      @click="
                                        (newNum) =>
                                          changeImageNumberInAlbum(newNum, item._id, slide.url)
                                      "
                                    >
                                      <a-menu-item
                                        v-for="num in Array.from(
                                          { length: item.img.length },
                                          (_, i) => i + 1,
                                        )"
                                        :key="num"
                                      >
                                        placez-le numéro {{ num }}
                                      </a-menu-item>
                                    </a-menu>
                                  </template>
                                  <a-button>
                                    Numéro {{ slide.order ? slide.order : '' }}
                                    <DownOutlined />
                                  </a-button>
                                </a-dropdown>
                              </a-tooltip>
                              <a-tooltip>
                                <template #title>Supprimer l'image</template>
                                <a-button @click="deleteImageFromAlbum(item._id, slide.url)"
                                  ><DeleteOutlined
                                /></a-button>
                              </a-tooltip>
                            </a-button-group>
                          </div>
                        </div>
                        <img :src="baseUrl + slide.url" style="width: auto" />
                      </div>
                    </slide>
                    <template #addons>
                      <navigation />
                      <pagination />
                    </template>
                  </carousel>
                </template>
                <a-card-meta>
                  <template #title>
                    <div
                      class="mb-3 mt-2"
                      style="overflow-wrap: break-word; white-space: break-spaces !important"
                    >
                      {{ item.title }}
                    </div>
                  </template>
                  <template #description>
                    <div class="mb-3 mt-2">{{ item.desc }}</div>
                    <a-button-group>
                      <a-tooltip>
                        <template #title>Modifier le titre/déscription</template>
                      <a-button @click="updateContent(item._id)"><EditOutlined /></a-button>
                      </a-tooltip>
                      <a-tooltip>
                        <template #title>Ajouter des images a l'album</template>
                      <a-button @click="addImgeToAlbum(item._id)"><PlusCircleOutlined /></a-button>
                      </a-tooltip>
                      <a-tooltip>
                        <template #title>Modifier l'ordre de l'album</template>
                        <a-dropdown>
                          <template #overlay>
                            <a-menu @click="(e) => changeNumber(e, item)">
                              <a-menu-item
                                v-for="num in Array.from({ length: count }, (_, i) => i + 1)"
                                :key="num"
                              >
                                placez-le numéro {{ num }}
                              </a-menu-item>
                            </a-menu>
                          </template>
                          <a-button>
                            Numéro {{ item.order ? item.order : '' }}
                            <DownOutlined />
                          </a-button>
                        </a-dropdown>
                      </a-tooltip>
                      <a-tooltip v-if="item.status == 'active'">
                        <template #title>Desactiver l'album</template>
                        <a-button @click="changeImageStatus(item, 'inactive')"
                          ><DeleteOutlined
                        /></a-button>
                      </a-tooltip>
                      <a-tooltip v-if="item.status == 'inactive'">
                        <template #title>Activer l'album</template>
                        <a-button @click="changeImageStatus(item, 'active')"
                          ><ToTopOutlined
                        /></a-button>
                      </a-tooltip>
                    </a-button-group>
                  </template>
                </a-card-meta>
              </a-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" :width="1200" @cancel="handleCancel">
      <img alt="Image" style="width: 100%; height: auto" :src="baseUrl + previewImage" />
    </a-modal>
    <a-modal
      v-model:visible="visibleModal"
      title="Ajouter un album"
      :width="400"
      style="height: 100px"
      ok-text="Ajouter"
      @cancel="cancel"
      @ok="addImage"
    >
      <a-form ref="formRef" label-align="left" class="container" :rules="rules" :model="formState">
        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Titre" name="title">
              <a-input v-model:value="formState.title" name="title" placeholder="Titre" />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Description" name="desc">
              <a-textarea
                v-model:value="formState.desc"
                name="desc"
                placeholder="Description"
                :row="4"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col md-6">
            <a-upload-dragger
              :file-list="fileList"
              name="file"
              :multiple="true"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              :custom-request="()=>{return true}"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">
                Cliquez ou faites glisser le fichier vers la zone à télécharger
              </p>
              <p class="ant-upload-hint">Prise en charge d'un téléchargement unique ou groupé.</p>
            </a-upload-dragger>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Ordre dans l'album" name="Imgorder">
              <a-input
                v-model:value="formState.Imgorder"
                name="Imgorder"
                placeholder="Ordre dans l'album"
              />
            </a-form-item>
          </div>
        </div>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="addImageToAlbumModal"
      title="Ajouter un image"
      :width="400"
      style="height: 100px"
      ok-text="Ajouter"
      @cancel="cancel"
      @ok="reloadPage"
    >
      <div class="row">
        <div class="col md-12">
          <a-upload-dragger
            :file-list="fileList2"
            :multiple="false"
            :remove="handleRemove"
            :before-upload="beforeUpload"
            :custom-request="addImageToAlbumCR"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">
              Cliquez ou faites glisser le fichier vers la zone à télécharger
            </p>
            <p class="ant-upload-hint">Prise en charge d'un téléchargement unique ou groupé.</p>
          </a-upload-dragger>
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="visibleModalUpdate"
      title="Modifier l'album"
      :width="400"
      style="height: 100px"
      ok-text="Modifier"
      @cancel="cancel"
      @ok="updateAlbum"
    >
      <a-form ref="formRef" label-align="left" class="container" :rules="rules" :model="formState">
        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Titre" name="title">
              <a-input v-model:value="formState.title" name="title" placeholder="Titre" />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Description" name="desc">
              <a-textarea
                v-model:value="formState.desc"
                name="desc"
                placeholder="Description"
                :row="4"
              />
            </a-form-item>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import VbHeadersTag from '@/components/widgets/Headers/Tag'
import VbHeadersCardHeader4 from '@/components/widgets/Headers/CardHeader4'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { ref, computed, reactive, toRaw } from 'vue'
import {
  DeleteOutlined,
  SearchOutlined,
  ToTopOutlined,
  DownOutlined,
  PlusCircleOutlined,
  EditOutlined,
} from '@ant-design/icons-vue'
import VbControlsButton1 from '@/@vb/widgets/Controls/Button'
import { message } from 'ant-design-vue'
import ApiClient from '@/services/axios'

export default {
  components: {
    EditOutlined,
    ToTopOutlined,
    SearchOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    Carousel,
    Slide,
    VbHeadersTag,
    VbHeadersCardHeader4,
    VbControlsButton1,
    DownOutlined,
    Pagination,
    Navigation,
  },
  emits: ['updateData'],
  setup(_, { emit }) {
    const visibleModal = ref(false)
    const addImageToAlbumModal = ref(false)
    const visibleModalUpdate = ref(false)
    const fileList = ref([])
    const baseUrl = ref(process.env.VUE_APP_API_URL)
    const dataSource = ref([])
    const formRef = ref()
    const currentAlbumID = ref()
    const formState = reactive({
      title: '',
      Imgorder: undefined,
      desc: '',
    })
    ApiClient.post('/gallery/filter', {
      query: {},
    })
      .then((res) => {
        res.data.sort((a, b) => a.status.localeCompare(b.status))
        dataSource.value = res.data
      })
      .catch((err) => console.log(err))
    const count = computed(
      () => [...dataSource.value].filter((elem) => elem.status == 'active').length,
    )
    const previewVisible = ref(false)
    const previewImage = ref('')
    const headerData = ref({
      title: 'Gestion des Galeries',
      icon: 'fe fe-users',
      res: {},
    })

    const changeNumber = ({ key }, item) => {
      ApiClient.patch('/gallery/' + item._id, {
        data: { order: key },
      })
        .then(() => {
          dataSource.value = dataSource.value.map((slider) => {
            if (slider._id == item._id) return { ...slider, order: key }
            else return slider
          })
        })

        .catch(function () {
          message.error("Impossible de supprimer l'image.")
        })
    }
    const handlePreview = (url) => {
      previewImage.value = url
      previewVisible.value = true
    }
    const changeImageStatus = (item, newStatus) => {
      ApiClient.patch('/gallery/' + item._id, {
        data: { status: newStatus },
      })
        .then(() => {
          visibleModal.value = false
          message.success(`image ${newStatus == 'active' ? 'activé' : 'Désactivé'}`)
          dataSource.value = dataSource.value.map((slider) => {
            if (slider._id == item._id) return { ...slider, status: newStatus }
            else return slider
          })
        })

        .catch(function () {
          message.error("Impossible de supprimer l'image.")
        })
    }
    const handleCancel = () => {
      previewVisible.value = false
      addImageToAlbumModal.value = false
      visibleModalUpdate.value = false
    }
    const addSlider = () => {
      visibleModal.value = true
    }
    const handleChange = (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
    }
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!')
      }

      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
      }
      if (isJpgOrPng && isLt2M) {
        fileList.value = [...fileList.value, file]
      }
      return isJpgOrPng && isLt2M
    }
    const updateAlbum = () => {
      const data = toRaw(formState)
      ApiClient.patch('/gallery/' + currentAlbumID.value, { data })
        .then(() => {
          dataSource.value = dataSource.value.map((obj) =>
            obj._id === currentAlbumID.value ? { ...obj, title: formState.title,desc: formState.desc } : obj,
          )
          visibleModalUpdate.value = false
        })

        .catch(function () {
          message.error("Impossible de modifier l'album.")
        })
    }
    const addImageToAlbum = (albumId) => {
      let formData = new FormData()
      formData.append('img', fileList.value[0], albumId)
      const { Imgorder } = toRaw(formState)
      if (Imgorder) formData.append('Imgorder', Imgorder)
      ApiClient.patch('/gallery/files/' + albumId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          fileList.value.shift()
          if (fileList.value==0) {emit('updateData')}
          if (fileList.value.length>0) addImageToAlbum(albumId)
        })
        .catch(function () {
          message.error("Impossible de télécharger l'image.")
        })
    }
    const addImage = () => {
      formRef.value.validate().then(() => {
        const { title, desc } = toRaw(formState)
        ApiClient.put('/gallery', {
          data: desc?{ title, desc }:{title},
        })
          .then((res) => {
            addImageToAlbum(res.data._id)
          })
          .catch(() => {
            message.warning("Impossible d'ajouter les information, Le titre est requis")
          })
          .finally(message.success("Album ajoutée"))
      })
    }
    const cancel = () => {
      formRef.value.resetFields()
      fileList.value = []
      visibleModal.value = false
    }
    const deleteImageFromAlbum = (_id, url) => {
      console.info(url)
      ApiClient.patch(`/gallery/img/${_id}`, { url })
        .then(({ data }) => {
          message.success('Image supprimé')
          emit('updateData')
          /*
        dataSource.value = dataSource.value.map(album=>{
          if(album._id==_id) return {
            ...album,
            img:data.img
          }
          else return album
        })*/
        })
        .catch(() => message.error('Image non supprimé'))
    }
    const changeImageNumberInAlbum = ({ key }, _id, url) => {
      ApiClient.patch(`/gallery/img/${_id}/${key}`, { url })
        .then(({ data }) => {
          message.success('Image numéro modifié')
          emit('updateData')
          /*
        dataSource.value = [...dataSource.value].map(album=>{
          if(album._id==_id) return {
            ...album,
            img:data.img
          }
          else return album
        })
        */
        })
        .catch(() => message.warning('Opération non complet'))
    }
    const addImageToAlbumCR = () => {
      if (!currentAlbumID.value) message.warning('Veuiller réessayez')
      else addImageToAlbum(currentAlbumID.value)
    }
    return {
      rule: {
        title: [
          {
            required: true,
            message: 'Le titre est obligatoire',
            trigger: 'blur',
          },
        ],
      },
      dataSource,
      headerData,
      updateAlbum,
      previewImage,
      previewVisible,
      handleCancel,
      handlePreview,
      visibleModal,
      fileList,
      handleChange,
      addImage,
      beforeUpload,
      handleRemove,
      addSlider,
      baseUrl,
      changeImageStatus,
      count,
      changeNumber,
      formState,
      formRef,
      cancel,
      deleteImageFromAlbum,
      changeImageNumberInAlbum,
      addImageToAlbumCR,
      addImageToAlbumModal,
      visibleModalUpdate,
      currentAlbumID,
      reloadPage: () => {
        emit('updateData')
      },
      addImgeToAlbum: (_id) => {
        currentAlbumID.value = _id
        addImageToAlbumModal.value = true
      },
      updateContent: (_id) => {
        currentAlbumID.value = _id
        let foundAlbum = dataSource.value.find((x) => x._id === _id)
        formState.title = foundAlbum.title
        formState.desc = foundAlbum.desc
        visibleModalUpdate.value = true
      },
    }
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="less" scoped>
.ant-modal-body {
  height: 220px;
}
</style>
