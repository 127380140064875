<template>
    <gallery :key="key" @updateData="updateData"/>
</template>
<script>
import Gallery from "./Gallery"

export default {
    components:{
        Gallery
    },
    data:function(){
        return{
            key:1
        }
    },
    methods:{
        updateData(){
            this.key = this.key + 1
        }
    }
}
</script>